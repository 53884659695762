<template>
  <b-card class="row-height" no-body>
    <div class="mb-1">
      <b-card no-body class="card-statistics" style="">
        <b-card-body class="statistics-body">
          <h2 class="text-purple font-weight-bold mb-1">
            <b-link class="btn btn-outline-primary" to="/">
              <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
              {{ $t("back") }}
            </b-link>
            {{ $t("deposit_funds") }}
          </h2>
          <br /><br />
          {{ $t("deposit_subtitle") }} <br />
          <br />
          {{ $t("introductions") }}:
          <b-row>
            <b-col
              v-for="(item, index) in invoiceData"
              :key="index"
              md="3"
              sm="6"
              class="mb-2 mb-md-0 col-md-12 mt-3 mb-3"
            >
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="'light-' + item.variant">
                    <feather-icon size="24" :icon="item.icon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto row">
                  <h4 class="col-xl-2 col-lg-3 col-md-6 font-weight-bolder">
                    {{ $t(item.text) }}
                  </h4>

                  <h4
                    class="col-xl-3 col-lg-4 col-md-6 font-weight-bolder mb-0"
                    v-html="item.value"
                  ></h4>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

          <br /><br /><br />
          <b-col class="alert alert-primary p-2 col-md-12">
            <div class="row ml-1">
              <feather-icon variant="primary" size="20" icon="InfoIcon" />
              <h5 class="text-primary ml-1">
                {{ $t("deposit_important_note") }}
              </h5>
            </div>
          </b-col>
        </b-card-body>
      </b-card>
      <br />
      <br /><br />
      <br />
      <br /><br />
      <b-col class="col-md-12" align="end">
        <b-button variant="primary" @click="setCookpitToCompleted">{{
          $t("i_will_do_it")
        }}</b-button>
      </b-col>
    </div>
  </b-card>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  name: "TransferSymbolicAmount",
  data() {
    return {
      iban: "",
      bank_name: "",
      bank_address: "",
      betrag: "",
      beneficiary: {
        name: "",
        address: "",
        zip: "",
        city: "",
        state: "",
      },
      refference_num: "",
      bic: "",
      clearing: "",
      invoiceData: [],
    };
  },
  created() {
    this.refference_num =
      this.$store.state.app.company &&
      this.$store.state.app.company.value &&
      this.$store.state.app.company.value.id_code
        ? this.$store.state.app.company.value.id_code
        : "/";

    this.invoiceData = [
      {
        text: "betrag",
        value: "CHF 5.00",
        icon: "DollarSignIcon",
        variant: "success",
      },
      {
        text: "beneficiary",
        value:
          "Aequitex AG" +
          "<br />" +
          "Gubelstrasse 24" +
          "<br />" +
          "6300 Zug, Switzerland",
        icon: "MapPinIcon",
        variant: "success",
      },
      {
        text: "IBAN",
        value: "CH84 0026 7267 1195 0102 E",
        icon: "CreditCardIcon",
        variant: "primary",
      },
      {
        text: "bank_name",
        value: "UBC",
        icon: "HomeIcon",
        variant: "secondary",
      },
      {
        text: "bic",
        value: "UBSWCHZH80A",
        icon: "CreditCardIcon",
        variant: "info",
      },
      {
        text: "Clearing",
        value: "787",
        icon: "LockIcon",
        variant: "warning",
      },
      {
        text: "reference_number_payment_reason",
        value: this.refference_num,
        icon: "DollarSignIcon",
        variant: "success",
      },
    ];
  },
  methods: {
    changeStatus() {
      this.$http.post("/buyer/changeThirdTaskStatus").then((res) => {
        if (res) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(res.data.message),
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          this.$router.push("/");
        }
      });
    },
    setCookpitToCompleted() {
      this.$http.post("/buyer/updateCookpitTransfer").then((res) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t(res.data.message),
            icon: "SuccessIcon",
            variant: "success",
          },
        });
        this.$router.push("/");
      });
    },
  },
};
</script>
