<template>
    <div class="container mt-5">
        <h2>Establishment of the Beneficial Owner's Identity</h2>
         <b-form-group :label="$t('contracting_partner')" class="mb-4" label-for="contracting_partner1">
            <validation-provider
                #default="{ errors }"
                name="contracting partner1"
                rules="required"
            >
                <b-form-textarea
                    id="contracting_partner1"
                    required
                    rows="3"
                    v-model="localForm.contractingPartnerStep2"
                    disabled = true
                ></b-form-textarea>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
            </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2 mb-4" label="The contracting partner herewith declares, (tick the appropriate box)">
            <b-form-radio-group name="declaration" required v-model="localForm.step2_question1" :disabled="disableAllInputs">
                <b-form-radio class="mt-1" name="d2" value="contracting_party">that the contracting party is the
                    beneficial owner of the assets involved
                </b-form-radio>
                <br>
                <b-form-radio class="mt-1" name="d2" value="following_person">that the following person(s) is/are the
                    beneficial owner(s) of the assets involved:
                </b-form-radio>
            </b-form-radio-group>
        </b-form-group>
        <div v-if="localForm.step2_question1 == 'following_person'">

            <b-form-group :label="$t('last_name')" label-for="beneficial_owner_last_name">
                <validation-provider
                    #default="{ errors }"
                    name="Last name"
                    rules="required"
                >
                    <b-form-input
                        id="beneficial_owner_last_name"
                        required
                        v-model="localForm.beneficialOwner.lastName"
                        :disabled="disableAllInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('fist_name')" label-for="beneficial_owner_last_name">
                <validation-provider
                    #default="{ errors }"
                    name="First name"
                    rules="required"
                >
                    <b-form-input
                        id="beneficial_owner_fist_name"
                        required
                        v-model="localForm.beneficialOwner.firstName"
                        :disabled="disableAllInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('birthday')" label-for="beneficial_owner_bod">
                <validation-provider
                    #default="{ errors }"
                    name="Date of Birth"
                    rules="required"
                >
                    <b-form-input
                        id="beneficial_owner_bod"
                        required
                        type="date"
                        v-model="localForm.beneficialOwner.dateOfBirth"
                        :disabled="disableAllInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('nationality')" label-for="beneficial_owner_nationality">
                <validation-provider
                    #default="{ errors }"
                    name="Nationality"
                    rules="required"
                >
                    <b-form-input
                        id="beneficial_owner_nationality"
                        required
                        v-model="localForm.beneficialOwner.nationality"
                        :disabled="disableAllInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('address')" label-for="beneficial_owner_address">
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required"
                >
                    <b-form-input
                        id="beneficial_owner_address"
                        required
                        v-model="localForm.beneficialOwner.address"
                        :disabled="disableAllInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('country')" label-for="beneficial_owner_country">
                <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                >
                    <b-form-input
                        id="beneficial_owner_country"
                        required
                        v-model="localForm.beneficialOwner.country"
                        :disabled="disableAllInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>
        </div>

    </div>

</template>

<script>

  import {ValidationProvider} from "vee-validate";
  import {required} from "@validations";
  import {
    BAlert,
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
  } from "bootstrap-vue";

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      BFormRadioGroup,
      BAlert,
      BInputGroup,
      BInputGroupPrepend,
      ValidationProvider,
    },
    props: {
      // contractingPartnerStep2: String,
      step2_question1: String,
      beneficialOwner: Object,
      disableAllInputs: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        // localForm: {
        //   contractingPartnerStep2: null,
        //   step2_question1: null,
        //
        //   beneficialOwner: {
        //     firstName: null,
        //     lastName: null,
        //     dateOfBirth: null,
        //     nationality: null,
        //     address: null,
        //     country: null,
        //   }
        //
        // }

        localForm: {
          contractingPartnerStep2: this.contractingPartnerStep2,
          step2_question1: this.step2_question1,
          beneficialOwner: {...this.beneficialOwner}
        }

      };
    },
    watch: {
      step2_question1(newVal) {
        this.localForm.step2_question1 = newVal;
      },
      localForm: {
        deep: true,
        handler(newValue) {
          this.$emit('merge-forms', newValue);
        }
      },
      beneficialOwner: {
        handler(newVal) {
          this.localForm.beneficialOwner = {...newVal};
        },
        deep: true
      },

    },

    created() {
      this.getCompany();
    },

    methods: {
      getCompany() {
        this.$http
            .post("/online_form/get_company")
            .then((res) => {

              this.localForm.contractingPartnerStep2 = res.data.value.name + ' ( ' + res.data.value.address + ' ) '

            });
      },
    }
  }

</script>

<style scoped>

</style>
