var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-5"},[_c('h2',[_vm._v("Establishment of the Beneficial Owner's Identity")]),_c('b-form-group',{staticClass:"mb-4",attrs:{"label":_vm.$t('contracting_partner'),"label-for":"contracting_partner1"}},[_c('validation-provider',{attrs:{"name":"contracting partner1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"contracting_partner1","required":"","rows":"3","disabled":"true"},model:{value:(_vm.localForm.contractingPartnerStep2),callback:function ($$v) {_vm.$set(_vm.localForm, "contractingPartnerStep2", $$v)},expression:"localForm.contractingPartnerStep2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2 mb-4",attrs:{"label":"The contracting partner herewith declares, (tick the appropriate box)"}},[_c('b-form-radio-group',{attrs:{"name":"declaration","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.step2_question1),callback:function ($$v) {_vm.$set(_vm.localForm, "step2_question1", $$v)},expression:"localForm.step2_question1"}},[_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"d2","value":"contracting_party"}},[_vm._v("that the contracting party is the beneficial owner of the assets involved ")]),_c('br'),_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"d2","value":"following_person"}},[_vm._v("that the following person(s) is/are the beneficial owner(s) of the assets involved: ")])],1)],1),(_vm.localForm.step2_question1 == 'following_person')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('last_name'),"label-for":"beneficial_owner_last_name"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_last_name","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.beneficialOwner.lastName),callback:function ($$v) {_vm.$set(_vm.localForm.beneficialOwner, "lastName", $$v)},expression:"localForm.beneficialOwner.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3607368564)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('fist_name'),"label-for":"beneficial_owner_last_name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_fist_name","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.beneficialOwner.firstName),callback:function ($$v) {_vm.$set(_vm.localForm.beneficialOwner, "firstName", $$v)},expression:"localForm.beneficialOwner.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,290594502)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('birthday'),"label-for":"beneficial_owner_bod"}},[_c('validation-provider',{attrs:{"name":"Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_bod","required":"","type":"date","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.beneficialOwner.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.localForm.beneficialOwner, "dateOfBirth", $$v)},expression:"localForm.beneficialOwner.dateOfBirth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,2583489248)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('nationality'),"label-for":"beneficial_owner_nationality"}},[_c('validation-provider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_nationality","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.beneficialOwner.nationality),callback:function ($$v) {_vm.$set(_vm.localForm.beneficialOwner, "nationality", $$v)},expression:"localForm.beneficialOwner.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,253727851)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('address'),"label-for":"beneficial_owner_address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_address","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.beneficialOwner.address),callback:function ($$v) {_vm.$set(_vm.localForm.beneficialOwner, "address", $$v)},expression:"localForm.beneficialOwner.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,1089521323)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('country'),"label-for":"beneficial_owner_country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_country","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.localForm.beneficialOwner.country),callback:function ($$v) {_vm.$set(_vm.localForm.beneficialOwner, "country", $$v)},expression:"localForm.beneficialOwner.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,545299371)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }