 
<template>
  <div>
    <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      v-model.trim="dialog"
      id="modal-center"
      :title="singleFile && singleFile.title ? singleFile.title : ''"
    >
      <template #modal-footer="{}">
        <b-button
          v-if="!singleFile"
          size="sm"
          variant="success"
          @click="submit"
        >
          Save
        </b-button>
        <b-button
          v-if="!singleFile"
          size="sm"
          variant="outline-secondary"
          @click="addNew"
        >
          {{ $t("new_debtor") }}
        </b-button>
        <b-button
          v-if="singleFile"
          size="sm"
          variant="outline-danger"
          @click="cancel"
        >
          {{ $t("cancel") }}
        </b-button>
      </template>
      <validation-observer
        ref="companyRegisterValidation"
        name="RegisterCompany"
      >
        <b-form @reset="onReset">
          <div class="col-md-12">
            <b-row m-0 p-0>
              <b-col cols="12" xl="12" md="12" lg="12">
                <b-form-group
                  id="deptor"
                  :label="$t('deptor') + ':'"
                  label-for="deptor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="debtor"
                    rules="required"
                  >
                    <b-form-select
                      :options="companies"
                      value-field="id"
                      text-field="name"
                      v-model.trim="debtor"
                    >
                    </b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" lg="12">
                <b-form-group
                  id="payment_date"
                  :label="$t('payment_date') + ':'"
                  label-for="payment_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="payment_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      no-flip
                      id="payment_date"
                      @input="changeDueDate"
                      v-model.trim="payment_date"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      required
                    ></b-form-datepicker>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" lg="12">
                <b-form-group
                  id="due_date"
                  :label="$t('due_date') + ':'"
                  label-for="due_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="due_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="due_date"
                      no-flip
                      v-model.trim="due_date"
                      :min="due_date"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      required
                    ></b-form-datepicker>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" lg="12">
                <b-form-group
                  id="amount"
                  :label="$t('amount') + ':'"
                  label-for="amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required"
                  >
                    <b-form-input
                      id="amount"
                      type="number"
                      v-model.trim="amount"
                      required
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <img src="/new-loader.svg" v-if="showLoader" />
              </b-col>
            </b-row>
          </div>
        </b-form>
        <b-card class="alert alert-secondary">
          <b-row>
            <b-col cols="12">
              {{ $t('debtor_modal_message') }}
            </b-col>
          </b-row>

        </b-card>
      </validation-observer>
    </b-modal>
    <AddNewCompnayModal
      :file="file"
      :showDialog="addNewComp"
      @closeAddCompModal="closeAddCompModal"
    ></AddNewCompnayModal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import AddNewCompnayModal from "./AddNewCompanyModal.vue";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BAlert,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    AddNewCompnayModal,
  },
  data() {
    return {
      filteredOptions: [],
      showLoader: false,
      disableInputs: false,
      company_id: this.$store.state.auth.user.company_id,
      debtor: null,
      payment_date: "",
      due_date: "",
      amount: 0,
      companies: [],
      dialog: false,
      addNewComp: false,
      new: true,
    };
  },
  name: "CompanyModal",
  props: ["file", "showDialog", "invoiceId", "singleFile"],
  created() {
    this.getCompanies();
  },
  watch: {
    showDialog(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.dialog = newValue;
      }
    },
    singleFile(newValue, oldValue) {
      if (newValue != null && newValue !== oldValue) {
        this.amount = this.singleFile.amount;
        this.due_date = this.singleFile.due_date;
        this.payment_date = this.singleFile.created_at;
        this.new = false;
      } else {
        this.amount = 0;
        this.due_date = "";
        this.payment_date = "";
        this.new = true;
      }
      this.getCompanies();
    },
  },
  computed: {},
  methods: {
    changeDueDate() {
      let parsed = new Date(this.payment_date);
      parsed.setDate(parsed.getDate() + 3);
      this.due_date = parsed;
    },

    getCompanies() {
      this.$http
        .post("/company/admin/list?")
        .then((response) => {
          if (response.data.status == 200) {
            this.companies = response.data.value.data;
            if (!this.new) {
              response.data.value.data.forEach((el) => {
                if (el.id == this.singleFile.company_id) {
                  this.debtor = el.id;
                }
              });
            } else {
              this.debtor = null;
            }
          }
        })
        .catch((error) => {});
    },
    closeAddCompModal() {
      this.addNewComp = false;
      // this.$emit("closeModal");
    },
    showSuccessMessage() {
      let text = this.$t("invoice_information_saved_successfully");
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((res) => {
        this.$emit("closeModal");
        this.$emit("getAll");
      });
    },
    submit() {
      this.$refs.companyRegisterValidation.validate().then((success) => {
        if (success) {
          let data = {
            debtor_id: this.debtor,
            payment_date: this.payment_date,
            due_date: this.due_date,
            amount: Number(this.amount),
          };
          this.$http
            .post(`/invoice/storeData/${this.invoiceId}`, data)
            .then((res) => {
              if (res) {
                this.showSuccessMessage();
              }
            });
        }
      });
    },
    addNew() {
      this.addNewComp = true;
      this.$emit("closeModal");
    },
    cancel() {
      this.$emit("closeModal");
    },
    onSelected() {
      this.successMessage = false;
    },
    getSuggestionValue(value) {
      this.company_name = value.item;
    },

    onReset(event) {
      event.preventDefault();
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
