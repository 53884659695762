<template>
  <div>
    <b-card v-if="company != null">
      <div class="col-md-12">
        <h3 class="light-blue">{{ $t("identification_title") }}</h3>

        <br />

        <div>
          {{ $t("identification_desc_1") }}
        </div>
        <br />
        <div>
          {{ $t("identification_desc_2") }}
        </div>
        <br />

        <table
          style="
            font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;
            width: 100%;
          "
          role="presentation"
        >
          <tbody>
            <tr>
              <td
                class="sm-px-24"
                style="
                  --bg-opacity: 1;
                  background-color: #ffffff;
                  background-color: rgba(255, 255, 255, var(--bg-opacity));
                  border-radius: 4px;
                  font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;
                  font-size: 14px;
                  line-height: 35px !important;
                  padding: 48px;
                  text-align: left;
                  --text-opacity: 1;
                  color: #626262;
                  color: rgba(98, 98, 98, var(--text-opacity));
                  padding-top: 20px;
                  padding-bottom: 10px !important;
                "
              >
                <p
                  style="
                    font-weight: 700;
                    font-size: 20px;
                    margin-top: 0;
                    --text-opacity: 1;
                    color: #4397f0 !important;
                  "
                >
                  {{ $t("proxy") }}
                </p>
                <p style="margin: 0 0 24px; font-size: 12px">
                  {{ $t("the_following_power_of_attorney_is_hereby_granted") }}
                </p>
                <p style="margin: 0 0 24px; font-size: 12px">
                  <strong>
                    {{ $t("principal") }}
                  </strong>
                  {{ $t("hereinafter_referred_to_as_the_seller") }}
                  <br />
                  <span style="color: #4397f0"
                    >{{ company.name }}, {{ company.address }},
                    {{ company.address_nr }}, {{ company.zip }},
                    {{ company.city }}</span
                  >
                </p>
                <p style="margin: 0 0 24px; font-size: 12px">
                  <strong>{{ $t("authorised_representative") }}: </strong>

                  <br />
                  <span style="color: #4397f0"
                    >Aequitex AG, Gubelstrasse 24, 6300 Zug, Switzerland</span
                  >
                </p>
                <p style="margin: 0 0 24px; font-size: 12px">
                  <strong>{{ $t("content_of_the_proxy") }}: </strong>
                  <br />
                  {{
                    $t(
                      "proxy_hereby_granted_to_fulfile_sellers_obligations_receivables_via_hiveterminals_platform"
                    )
                  }}
                  .
                  {{
                    $t(
                      "hiveterminal_may_perform_actions_necessary_connection_with_service_offered_by_hiveterminal"
                    )
                  }}
                  .
                </p>
                <p style="margin: 0 0 24px; font-size: 12px">
                  {{ $t("the_proxy_includes_in_particular") }}:
                  <br />

                  {{
                    $t(
                      "assignment_of_invoice_receivables_uploaded_by_the_seller"
                    )
                  }}
                  .
                </p>

                <p style="margin: 0 0 24px; font-size: 12px">
                  {{
                    $t(
                      "this_present_proxy_explicitly_includes_the_right_of_dual_representation_selfdealing_and_to_nominate_any_substitute_of_choice"
                    )
                  }}
                  .
                </p>

                <p style="margin: 0 0 24px; font-size: 12px">
                  {{
                    $t(
                      "this_present_proxy_shall_be_governed_by_the_laws_of_switzerland"
                    )
                  }}
                </p>

                <p style="margin: 0 0 24px; font-size: 12px">
                  <span style="color: #4397f0">Zug, {{ today }}</span>
                </p>

                <p style="margin: 0 0 24px; font-size: 12px">
                  <strong> {{ $t("the_seller") }} : </strong>
                  <br />
                  <span style="color: #4397f0">{{ company.name }}</span>
                </p>

                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                  <h6 class="col-md-5 p-0">
                    <p>{{ $t("your_phone_number") }}</p>
                    <b-input-group>
                      <b-input-group-prepend is-text id="phoneCountries">
                        <b-form-select
                          :options="countries"
                          v-model.trim="phoneContries"
                        >
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-tel"
                        v-model.trim="company.main_contact.tel"
                        @input="checkPhoneFormat"
                        class="form-control"
                        required
                      >
                      </b-form-input>
                    </b-input-group>
                    <small
                      class="text-danger is-invalid"
                      v-if="phoneValidation"
                      >{{ phoneValidation }}</small
                    >
                  </h6>
                  <div class="col-md-12 mt-1 p-0">
                    <b-button variant="primary" @click="sendMTan()" :disabled="disableBtn" >{{
                      $t("request_mtan")
                    }}</b-button><img v-if="disableBtn" width="50" height="50" src="/new-loader.svg" />
                  </div>
                </div>

                <div class="col-md-12" v-if="mTanSent">
                  <b-row class="jumbotron" id="mtan-div">
                    <b-col class="col-md-12 mb-1 row">
                      <h5>
                        {{ $t("you_have_received_the_mTAN_on_your_mobile") }}
                      </h5>
                    </b-col>
                    <b-col class="col-md-12 row">
                      <div>
                        <b-form-input
                          v-model.trim="mTan"
                          placeholder=""
                          class="mr-1"
                        ></b-form-input>
                      </div>
                      <div>
                      
                        <b-button
                          variant="primary"
                          class="ml-2"
                          @click="verifyMTan()"
                          :disabled="resLoader"
                          >{{ $t("i_confirm") }}</b-button
                        >  <img v-if="resLoader" width="50" height="50" src="/new-loader.svg" />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
     
      </div>
      <br />
    </b-card>
  </div>
</template>
<script>
import { phone } from "phone";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
import Ripple from "vue-ripple-directive";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      disableBtn: false,
      resLoader:false,
      countries: this.$store.state.app.countries,
      countries_code: this.$store.state.app.countries_code,
      phoneContries: null,
      mTan: null,
      mTanSent: false,
      telCode: "",
      mtanValue: "",
      phoneValidation: "",
      phoneOptions: {
        phone: {
          delimiters: [" ", " ", " "],
          blocks: [2, 3, 2, 2],
        },
      },
      company: null,
      file1: null,
      pdfs: [],
      newFile: null,
      showButton: false,
      mainProps: {
        blank: false,
        blankColor: "#777",
        width: 1000,
        height: 1000,
        class: "m1",
      },
      mainPropsSecond: {
        blank: false,
        blankColor: "#777",
        width: 300,
        height: 300,
        class: "m1",
      },
    };
  },
  directives: {
    Ripple,
  },
  watch: {
    file1(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          if (this.pdfs.length < 3) {
            this.pdfs.push(newValue.name);
          }
        }
      }
    },
  },
  computed: {
    today() {
      return moment().format("DD.MM.YYYY");
    },
  },
  created() {
    if (localStorage.getItem("task_status")) {
      let tasks = JSON.parse(localStorage.getItem("task_status"));

      if (tasks == "0") {
        this.showButton = true;
      }
    }

    this.$http
      .post(`/company/show/${this.$store.state.auth.user.company_id}`)
      .then((res) => {
        this.company = res.data.value;
        this.phoneContries = this.company.main_contact.iso_code;
        console.log(this.company);
      });
  },
  methods: {
    checkPhoneFormat() {
      let validation = phone(this.company.main_contact.tel, {
        country: this.phoneContries,
        validateMobilePrefix: false,
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    async sendMTan() {
  if (this.phoneValidation.length < 1) {
    try {
      this.disableBtn= true;
      const response = await this.$http.post("/company/sign/proxy", {
        tel: this.getPhone(this.phoneContries, this.company.main_contact.tel),
        country: this.phoneContries,
        company_id: this.company.id,
      });
      
      if (response && response.data.status === 500) {
        this.disableBtn= false;
        const mess = response.data.message;
        const icon = "ErrorIcon";
        const variant = "danger";
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t(mess),
            icon,
            variant,
          },
        });
      } else {
        this.mTanSent = true;
        this.disableInputs = true;
        this.disableBtn= false;
        const mtan = document.getElementById("mtan-div");
        if (mtan) {
          mtan.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        
        const mess = "success";
        const icon = "SuccessIcon";
        const variant = "success";
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t(mess),
            icon,
            variant,
          },
        });
      }
    } catch (error) {
      this.disableBtn= false;

    }
  }
},

async verifyMTan() {
  if (this.mTan) {
    this.resLoader = true;
    try {
      const response = await this.$http.post("/company/sign/proxy/verify", { code: this.mTan });
      if (response.data.status === 200) {
        this.mTanSent = false;
        this.disableInputs = true;
        this.$router.push("/");
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t(response.data.message),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.disableInputs = false;
      }
    } catch (error) {
      this.resLoader = false;
    } finally {
      this.resLoader = false;
    }
  } else {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: this.$t("code_is_required"),
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });
    this.resLoader = false;
  }
},
    getPhone(iso, tel) {
      if (this.countries_code) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }

      return tel;
    },
    submit() {
      this.$http.post("/company/updateCookpitTaskStatus").then((res) => {
        if (res) {
          document.getElementById("updateSideBar").click();
          this.$router.push("/");
        }
      });
    },
    browseInput() {},
  },
};
</script>

<style scoped>
#phoneCountries select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group-text {
  padding: 0;
  border: 0 !important;
}
.text-gray {
  color: #5e5873;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
